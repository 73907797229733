import React, {useState} from "react";
import {Link, Link as RouterLink} from "react-router-dom";
import {Link as ScrollLink} from "react-scroll";
import styles from "../navbar/Navbar.module.css";
import {getImageUrl} from "../../utils";

export const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [openSub, setOpenSub] = useState({submenu: "", openSub: false});

    return (
        <nav className={styles.navbar}>
        <RouterLink className = {styles.title} to="/">Maggie Stewart</RouterLink>
        <div className = {styles.menu}>
            <img className = {styles.menuBtn}
                src={
                    menuOpen
                        ? getImageUrl("nav/closeIcon.png")
                        : getImageUrl("nav/menuIcon.png")
                    }
                alt = "menu-button"
                onClick ={() => setMenuOpen(!menuOpen)}
            />
            <ul className = {`${styles.menuItems} ${menuOpen && styles.menuOpen}`}
            onClick={() => setMenuOpen(false)}
            >
                <li><RouterLink to="/?scrollTo=education">Education</RouterLink></li>
                <li><RouterLink to="/experience">Experience</RouterLink></li>
                <li><RouterLink to="/projects">Projects</RouterLink></li>
                <li><RouterLink to="/interests">Interests</RouterLink></li>
            </ul>
        </div>
    </nav>
    );
};