import React, { useState } from "react"
import { getImageUrl } from "../../utils";
import styles from "./Interests.module.css";
import {Footer} from "../footer/Footer";

export const Interests = () => {

    const favShotsGrid = [
        getImageUrl('/interests/fav1.jpg'), getImageUrl('/interests/fav2.JPG'), getImageUrl('/interests/fav3.jpg'), getImageUrl('/interests/fav4.jpg')
        //,
        // getImageUrl('/interests/fav5.png'), getImageUrl('/interests/fav6.jpg'), getImageUrl('/interests/photo7.png'), getImageUrl('/interests/photo8.png')
     ];
    

    const travelGrid = [
        getImageUrl('/interests/travel1.png'), getImageUrl('/interests/travel2.png'), 
        getImageUrl('/interests/travel3.jpg'), getImageUrl('/interests/travel4.jpg'),
        getImageUrl('/interests/travel5.jpg'), getImageUrl('/interests/travel6.png'), 
        getImageUrl('/interests/travel7.png'), getImageUrl('/interests/travel8.JPG')
    ];

    const foodGrid = [
        getImageUrl('/interests/food1.JPG'), getImageUrl('/interests/food2.JPG'), 
        getImageUrl('/interests/food3.jpg'), getImageUrl('/interests/food4.jpg'),
        getImageUrl('/interests/food5.JPG'), getImageUrl('/interests/food6.jpg'), 
        getImageUrl('/interests/food7.JPG'), getImageUrl('/interests/food8.JPG')
    ];

    return (
    <section className = {styles.container} id = "interests">
        <h2 className = {styles.title}>Interests</h2>

        <div className = {styles.contentfavShots}>
            <h3 className = {styles.subtitle}>Favorite Shots</h3>
            <div className = {styles.photoGrid}>
                {favShotsGrid.map((photo, index) => (
                    <div key={index} >
                        <img src = {photo} className={styles.photoItem} alt = {`Photo ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>

        <div className = {styles.contentTravel}>
            <h3 className = {styles.subtitle}>Travels</h3>
            <div className = {styles.photoGrid}>
                {travelGrid.map((photo, index) => (
                    <div key={index} >
                        <img src = {photo} className={styles.photoItem} alt = {`Photo ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>

        <div className = {styles.contentFood}>
            <h3 className = {styles.subtitle}>Eats</h3>
            <div className = {styles.photoGrid}>
                {foodGrid.map((photo, index) => (
                    <div key={index} >
                        <img src = {photo} className={styles.photoItem} alt = {`Photo ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
        <Footer></Footer>
    </section>
    );
};