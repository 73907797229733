import React, {useState} from "react";
import {Link, Link as RouterLink} from "react-router-dom";
import {Link as ScrollLink} from "react-scroll";
import styles from "../footer/Footer.module.css";
import {getImageUrl} from "../../utils";

export const Footer = () => {
    return <section className = {styles.outside} id = "footer">
        <nav className={styles.footer}>
            <div className = {styles.menu}>
                <ul className = {styles.menuItems}>
                    <li>
                        <RouterLink
                            to="mailto:maggiestewartlsu@gmail.com"
                            rel="noopener noreferrer"
                            >
                            <img src={getImageUrl('icons/icons8-email.png')} className = {styles.EmailIcon} />
                        </RouterLink>
                        .
                    </li>
                    <li>
                        <RouterLink
                            to="https://github.com/maggiestewart"
                            target = "_blank"
                            rel="noopener noreferrer" 
                            >
                            <img src={getImageUrl('icons/github-mark-white.png')} className={styles.menuIcon} />
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink
                            to="https://www.linkedin.com/in/maggiestewart225/"
                            target = "_blank"
                            rel="noopener noreferrer" 
                            >
                            <img src={getImageUrl('icons/In-White-72@2x.png')} className = {styles.LIIcon} />
                        </RouterLink>
                    </li>
                </ul>
            </div>
        <br></br>
        <h3 className = {styles.footerInfo}> © 2025 Margaret Stewart - Updated 1/2025</h3>
        </nav>
    </section>;
}