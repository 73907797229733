
import styles from './App.module.css'
import React, {useEffect} from "react";

import { Navbar } from './components/navbar/Navbar.jsx'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation
} from "react-router-dom";
import { Me } from './components/me/Me.jsx'
import { About } from './components/about/About.jsx'
import { Experience } from './components/experience/Experience.jsx'
import { Projects } from './components/projects/Projects.jsx'
import { CanvaPortfolio } from './components/projects/CanvaPortfolio'
import { Interests } from './components/interests/Interests.jsx'
import { Footer } from './components/footer/Footer.jsx'

function App() {
    return (
      <div className={styles.App}>
      <Router>
      <Navbar />
            <Routes>
                <Route path="/" element={<Me />} />
                <Route path="/#education" element={<About />} />
                <Route
                    path="/experience"
                    element={<Experience />}
                />
                <Route path="/projects" element={<Projects />} />
                <Route path="/canvaportfolio" element={<CanvaPortfolio />} />
                <Route
                    path="/interests"
                    element={<Interests />}
                />
            </Routes>
          </Router>
      </div>
  );
}

export default App;
