import React, { useState } from "react"
import {getImageUrl} from "../../utils"
import styles from "./Projects.module.css";
import {Footer} from "../footer/Footer";
import {Link as RouterLink} from "react-router-dom";


export const CanvaPortfolio = () => {

    return (
    <section className = {styles.container} id = "canvaportfolio">
        <h3 className={styles.title}>NAIL BAR & SPA MARKETING DESIGNS</h3>
        {/*<a href="https://www.instagram.com/nailbarspa225/" className = {styles.title} target="_blank" rel="noopener noreferrer">NAIL BAR & SPA MARKETING DESIGNS</a>*/}
        <div className = {styles.content}>
            <div className = {styles.projectGrid}>
                <a href="https://www.instagram.com/nailbarspa225/" className = {styles.projectLink} target="_blank" rel="noopener noreferrer">
                    <div className={styles.projectItem}>
                        <img src={getImageUrl('/canva/nail1.png')} className={styles.projectImage} />
                        {/*<br></br>
                        <h3>LibraryBR - Honors Thesis</h3>
                        <h4>Advised by Dr. Qingyang Wang</h4>*/}
                    </div>
                </a>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/nail2.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/nail3.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>
            </div>
        </div>

        <h3 className={styles.title}>STUDENT ORGANIZATION DESIGNS</h3>
        <div className = {styles.content}>
            <div className = {styles.projectGrid}>
                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/psp1.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/psp2.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/psp3.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/psp4.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/psp5.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/psp6.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>
            </div>
        </div>

        <h3 className={styles.title}>CLASS DESIGNS</h3>
        <div className = {styles.content}>
            <div className = {styles.projectGrid}>
                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/class1.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/class2.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/class3.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/class4.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/class5.png')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>

                <div className={styles.projectItem}>
                    <img src={getImageUrl('/canva/class6.jpg')} className={styles.projectImage} />
                    {/*<br></br>
                    <h3>LibraryBR - Honors Thesis</h3>
                    <h4>Advised by Dr. Qingyang Wang</h4>*/}
                </div>
            </div>
        </div>
        <Footer></Footer>
    </section>
    );
};