import React, { useState } from "react"
import {getImageUrl} from "../../utils"
import styles from "./Experience.module.css";
import {Footer} from "../footer/Footer";


export const Experience = () => {

    return (
    <section className = {styles.container} id = "experience">
        {/* PROFESSIONAL / WORK EXPERIENCES */}
        <h2 className={styles.title}>Professional Experience</h2>
        <div className = {styles.content}>
            <ul className = {styles.experienceItems}>
                <li className = {styles.experienceItem}>
                    <img src={getImageUrl("icons/geaux-communicate.png")} className = {styles.experienceIconsExc} alt = "LSU CxC Geaux Communicate logo" />
                    <div className = {styles.experienceText}>
                        <h3>LSU CxC - Graduate Assistant</h3>
                        <h4>August 2024 - Present</h4>
                        <p>
                            🏆 Distinguished Communicator Graduate - Spring 2024
                            <br></br>
                            📂 Manage program data to identify actionable insights and effectively address data anomalies.
                            <br></br>
                            ⚙️ Leverage background in Computer Science to develop automated processes.

                        </p>
                        <br></br><br></br>
                        <div className={styles.experiencePhotoGrid}>
                            {/*<p>Photo grid coming soon!</p>*/}
                            <img src={getImageUrl("experiences/cxc-grad.jpg")} alt={""} className={styles.experiencePhoto}/>
                            <img src={getImageUrl("experiences/cxc-grad-group.png")} alt={""} className={styles.experiencePhoto}/>
                        </div>
                    </div>
                </li>


                <li className = {styles.experienceItem}
                    onClick={() => window.open(
                        "https://www.linkedin.com/posts/maggiestewart225_erp-intern-businessautomation-activity-7232588372366483456-s7Of?utm_source=share&utm_medium=member_desktop",
                        "_blank"
                    )}
                    style={{ cursor: "pointer" }}>
                    <img src={getImageUrl("icons/BBP-Logo-White.png")} className = {styles.experienceIconsNoBorder} alt = "BBP Logo" />
                    <div className = {styles.bbpText}>
                        <h3>BBP Sales - ERP Software Developer Intern</h3>
                        <h4>December 2023 - August 2024</h4>
                        <p>
                            ⏩ Streamlined the transition from legacy to updated ERP systems by integrating user feedback.
                            <br></br>
                            🖋️ Engineered modern, intuitive UIs for Accounting and Sales modules.
                            <br></br>
                            ⚙️ Enhanced system performance by promoting scalable API integration.
                        </p>

                        <br></br> <br></br>

                        <h3>BBP Sales - Data Engineering & Business Automation Intern</h3>
                        <h4>June 2023 - May 2024</h4>
                        <p>
                            📈 Increased billing system capacity by 22 workdays/month by training 40 document scraping AI models.
                            <br></br>
                            💥 Eliminated 90% of inter-departmental requests by building a self-service API link between systems.
                            <br></br>
                            ⚙️ Automated commission payment query requests in external system by writing dynamic SQL queries.
                        </p>

                        <br></br> <br></br>

                        <h3>BBP Sales - Data Science & Web Development Intern</h3>
                        <h4>March 2023 - June 2023</h4>
                        <p>
                            📈 Extrapolated sensor data to develop machine learning models assessing data quality and accuracy.
                            <br></br>
                            🏷️ Configured and optimized Google Analytics tags to accurately track and analyze customer behavior.
                        </p>
                        <br></br><br></br>
                        <div className={styles.experiencePhotoGrid}>
                            <img src={getImageUrl("experiences/bbp-edited-1.jpg")} className={styles.experiencePhoto}/>
                            <img src={getImageUrl("experiences/bbp-2.jpg")} className={styles.experiencePhoto}/>
                        </div>
                    </div>
                </li>

            </ul>
        </div>

        <br></br>
        <br></br>

        {/* EXTRA CURRICULAR EXPERIENCES */}
        <h2 className={styles.title}>Community & Campus Involvement</h2>
        <div className = {styles.content}>
            <ul className = {styles.experienceItems}>
                <li className = {styles.experienceItem}>
                    <img src={getImageUrl("icons/MBAA.png")} className = {styles.mbaaIcon} alt = "MBA Association logo" />
                    <div className = {styles.bbpText}>
                        <h3>LSU MBA Association</h3>
                        <h4>August 2024 - Present</h4>
                        <p>
                            🤝 Connect with other Flores MBA students at a variety of events
                            <br></br>
                            💼 Engage in Lunch & Learn sessions to learn more about insights from local business partners
                        </p>
                        <br></br>
                        <div className={styles.experiencePhotoGrid}>
                            <img src={getImageUrl("experiences/mbaa-fallevent.JPG")} className={styles.experiencePhoto}/>
                            <img src={getImageUrl("experiences/mbaa-lunchlearn.jpg")} className={styles.experiencePhoto}/>
                        </div>
                    </div>
                </li>

                <li className = {styles.experienceItem}>
                    <img src={getImageUrl("icons/CongressionalApp.png")} className = {styles.otherIcon} alt = "Congressional App Challenge logo" />
                    <div className = {styles.bbpText}>
                        <h3>Congressional App Challenge Volunteer Judge</h3>
                        <h4>October 2023 - Present</h4>
                        <p>
                            🖥️ Assisted Congressman <u>John James (MI-10, 2023)</u> and Congresswoman <u>Cori Bush (MO-1, 2024)</u> in evaluating submissions for their respective districts
                            <br></br>
                            💡 Expanded my community involvement by assisting these districts with their app submissions, contributing to the review process and experiencing the creative problem-solving in young developers
                            <br></br>
                            💖 Experienced a full-circle moment as a former high school participant in the challenge (LA-6, 2017) placing 3rd in my district
                        </p>
                    </div>
                </li>

                <li className = {styles.experienceItem}
                    onClick={() => window.open(
                        "https://www.linkedin.com/posts/maggiestewart225_last-night-was-the-last-general-body-meeting-activity-7156310634165395457-Fl3J?utm_source=share&utm_medium=member_desktop",
                        "_blank"
                    )}
                    style={{ cursor: "pointer" }}>
                    <img src={getImageUrl("icons/SPMLogo.png")} className = {styles.experienceIconsSmall} alt = "Society of Peer Mentors logo" />
                    <div className = {styles.bbpText}>
                        <h3>Society of Peer Mentors</h3>
                        <h4>January 2021 - Present</h4>
                        <p>
                            🤝 Volunteered at STEM events in the Baton Rouge community to engage K-12 students with science, engineering, and robotics topics
                            <br></br>
                            💰 Managed an accurate record club expenses and dues for 176 active members, aiding the Fundraising Chair in coordinating fundraising initiatives
                            <br></br>
                            💰 Spearheaded the initiative to add SPM-branded items in the time capsule for the LSU Student Union's 60th anniversary celebration that will be opened in 2074
                            <br></br>
                            👋 Advised 3 groups of 6-8 Geaux Engineering incoming freshman on college life/CS at LSU to prepare them for success in their college career
                            <br></br>
                            👋 Fostered a team environment as Geaux Engineering freshman groups worked together through a set of problem solving challenges and a design project
                        </p>
                        <br></br>
                        <div className={styles.experiencePhotoGrid}>
                            {/*<p>Photo grid coming soon!</p>*/}
                            <img src={getImageUrl("experiences/spm-officers2324.JPG")} className={styles.experiencePhoto}/>
                            <img src={getImageUrl("experiences/spm-spr24grad.JPG")} className={styles.experiencePhoto}/>
                        </div>
                    </div>
                </li>


                <li className = {styles.experienceItem}>
                    <img src={getImageUrl("icons/LSUMBA.png")} className = {styles.experienceIcons} alt = "LSU MBA Program Logo" />
                    <div className = {styles.bbpText}>
                        <div onClick={() => window.open(
                            "https://www.linkedin.com/posts/maggiestewart225_mba-corporatecommunication-lsu-activity-7272636840749207553-hL2h?utm_source=share&utm_medium=member_desktop",
                            "_blank"
                            )}
                             style={{ cursor: "pointer" }}>
                            <h3>Corporate Communication Challenge</h3>
                            <h4>November 2024</h4>
                            <p>
                                🤝 Collaborated with my teammate to determine the most important characteristics we believe the next LSU College of Business dean should embody
                                <br></br>
                                🌟 Highlighted traits like being forward-thinking and an advocate for students, faculty, and the broader community
                            </p>
                            <br></br>
                            <div className={styles.experiencePhotoGrid}>
                                <img src={getImageUrl("experiences/mba-ccc-group.jpg")} className={styles.experiencePhoto}/>
                                <img src={getImageUrl("experiences/mba-ccc.jpg")} className={styles.otherExperiencePhoto}/>
                            </div>
                        </div>
                        <br></br> <br></br>

                        <div
                            onClick={() => window.open(
                            "https://www.linkedin.com/posts/maggiestewart225_startuplsu-activity-7260857780008308736-h39Y?utm_source=share&utm_medium=member_desktop",
                            "_blank"
                            )}
                             style={{ cursor: "pointer" }}>
                            <h3>Bald Truth Hair Co. - Startup LSU</h3>
                            <h4>November 2024</h4>
                            <div className={styles.descImg}>
                                <p>
                                    🏆 1st Place @ Startup LSU 2024
                                    <br></br>
                                    ✍️Designed an app prototype to create a welcoming and inclusive community for our users while staying aligned with Kalayna's vision
                                    <br></br>
                                    💼 Developed an understanding of business skills to launch a business venture
                                </p>

                                <img src={getImageUrl("experiences/bald-truth-app-mockup.PNG")} className={styles.experiencePhoto}/>
                            </div>
                            <br></br>
                            <div className={styles.experiencePhotoGrid}>
                                <img src={getImageUrl("experiences/bald-truth-group.jpg")} className={styles.experiencePhoto}/>
                                <img src={getImageUrl("experiences/bald-truth-hair-co-slides.jpg")} className={styles.otherExperiencePhoto}/>
                            </div>
                        </div>
                        <br></br> <br></br>

                        <div
                            onClick={() => window.open(
                            "https://www.linkedin.com/posts/maggiestewart225_yesterday-marked-the-end-of-a-challenging-activity-7232775051618435072-ua5l?utm_source=share&utm_medium=member_desktop",
                            "_blank"
                            )}
                             style={{ cursor: "pointer" }}>
                            <h3>MBA Launch Case Competition</h3>
                            <h4>August 2024</h4>
                            <p>
                                🏆 Awarded 1st place in our subdivision and 2nd Place overall based on presentation, slide deck, and Q&A scores
                                <br></br>
                                🤝 Collaborated with a group of 4 other incoming Flores MBA students to devise a business strategic plan for Tesla in 3 hours and present to a judge panel
                                <br></br>
                                💡 Developed a plan called Tesla Glide to encourage Tesla to utilize EV fleets to target the last-mile delivery industry as a new avenue for growth
                            </p>
                            <br></br>
                            <div className={styles.experiencePhotoGrid}>
                                <img src={getImageUrl("experiences/mba-launch.jpg")} className={styles.experiencePhoto}/>
                                <img src={getImageUrl("experiences/mba-launch-slides.jpg")} className={styles.otherExperiencePhoto}/>

                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <br></br><br></br>

        {/* CLASS PRESENTATIONS */}
        <h2 className={styles.title}>Class Presentations</h2>
        <div className = {styles.content}>
            <ul className = {styles.experienceItems}>
                <li className = {styles.experienceItem}
                    onClick={() => window.open(
                        "https://www.linkedin.com/posts/maggiestewart225_in-my-recent-marketing-class-our-group-developed-activity-7274789192876900353-H_GW?utm_source=share&utm_medium=member_desktop",
                        "_blank"
                    )}
                    style={{ cursor: "pointer" }}
                >
                    <img src={getImageUrl("icons/LSUMBA.png")} className = {styles.experienceIcons} alt = "LSU MBA Program Logo" />
                    <div className = {styles.bbpText}>
                        <h3>Marketing Management (BADM 7100) - Final Pitch Presentation</h3>
                        <h4>December 2024</h4>
                        <p>
                            💡 Over 7 weeks, my team developed the "Play Hard. Health Harder." campaign for Baton Rouge General Medical Center to target 18-24 year old males to take control of their own health
                            <br></br>
                            📖 Utilized the Storybook Framework to create an engaging storyline with a hero (target customer), villain (main issue), and clear guiding steps for the hero to engage with its guide (BRG)
                        </p>
                        <br></br>

                        <div className={styles.experiencePhotoGrid}>
                            <img src={getImageUrl("experiences/brg-group.jpg")} className={styles.experiencePhoto}/>
                            <img src={getImageUrl("experiences/brg-final.png")} className={styles.otherExperiencePhoto}/>
                        </div>
                    </div>
                </li>
            </ul>
        </div>


        <Footer></Footer>
    </section>
    );
};