import React, { useState, useEffect } from "react"
import { getImageUrl } from "../../utils"
import styles from "./About.module.css";
import {useLocation} from "react-router-dom";

export const About = () => {
    const [hoveredItem, setHoveredItem] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const images = {
        mba: getImageUrl("me/mba-launch-case.jpg"),
        cxc: getImageUrl("me/cxc-grad.jpg"),
        honors: getImageUrl("me/honors-grad.jpg"),
        default: getImageUrl("me/grad pics-57.jpg")
    };

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
    
        window.addEventListener('resize', handleResize);
        
        // Cleanup listener on unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const location = useLocation();
    useEffect (() => {
        const params = new URLSearchParams(location.search);
        const scrollTo = params.get("scrollTo");

        if (scrollTo === "education"){
            const educationElement = document.getElementById("education");
            if (educationElement) {
                educationElement.scrollIntoView({behavior: "smooth"});
            }
        }
    }, [location]);
    
    return (
    <section className = {styles.container} id = "education">
        <h2 className = {styles.title}>Education</h2>
        <div className = {styles.content}>
            <img 
                id = "photoMain"
                src={windowWidth >= 830 && hoveredItem ? images[hoveredItem] : getImageUrl("me/grad pics-57.jpg")}
                alt={windowWidth >= 830 && hoveredItem ? `${hoveredItem} image` : "default image"}
                className = {styles.meImg}
            />

            <ul className = {styles.aboutItems}>
                <li className = {styles.aboutItem} 
                    onMouseEnter={() => setHoveredItem("mba")}
                    onMouseLeave={() => setHoveredItem("default")}>
                    <img src={getImageUrl("icons/briefcase.png")} className = {styles.aboutIcons} alt = "Briefcase icon" />
                    <div className = {styles.aboutItemText}>
                        <h3>LSU Flores MBA Candidate</h3>
                        <h4>May 2026</h4>
                        <p>
                            📊 Concentration in Analytics & Emerging Tech
                            <br></br>
                            🏆 MBA Launch Case Competition 2nd place
                <br></br>
                        </p>
                    </div>
                </li>

                <li className = {styles.aboutItem} onMouseEnter={() => setHoveredItem("honors")}
                    onMouseLeave={() => setHoveredItem("default")}>
                    <img src={getImageUrl("icons/grad.png")} className = {styles.aboutIcons} alt = "Graduation hat icon" />
                    <div className = {styles.aboutItemText}>
                        <h3>LSU Computer Science Alumna</h3>
                        <h4>May 2024</h4>
                        <p>
                            🎓 Graduated Summa Cum Laude
                            <br></br>
                            🏆 College Honors - Ogden Honors College
                            <br></br>
                            💬 Distinguished Communicator - CxC (<a href="https://cxc.maggiestewart225.com/" target="_blank" rel="noreferrer" >View Portfolio</a>)
                            <br></br>
                            ✈️ LSU London & Edinburgh Study Abroad (Summer 2023)
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    );
};
